import React, { useState, useEffect } from 'react';
// import Swiper from 'react-id-swiper';
// import 'swiper/swiper.scss';

// import 'swiper/swiper.min.css'
import { Swiper } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import img0 from '../../static/view0.jpg';
import img1 from '../../static/view1.jpg';
import img2 from '../../static/view2.jpg';
import img3 from '../../static/view3.jpg';

const Gallery = () => {
  const [gallerySwiper, getGallerySwiper] = useState(null);
  const [thumbnailSwiper, getThumbnailSwiper] = useState(null);
  const gallerySwiperParams = {
    getSwiper: getGallerySwiper,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    centeredSlides: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  };
  useEffect(() => {
    if (
      gallerySwiper !== null &&
      gallerySwiper.controller &&
      thumbnailSwiper !== null &&
      thumbnailSwiper.controller
    ) {
      gallerySwiper.controller.control = thumbnailSwiper;
      thumbnailSwiper.controller.control = gallerySwiper;
    }
  }, [gallerySwiper, thumbnailSwiper]);
  return (
    <div>
      <Swiper {...gallerySwiperParams}>
        <div className={"swiper-slide"}><img src={img0} /></div>
        <div className={"swiper-slide"}><img src={img1} /></div>
        <div className={"swiper-slide"}><img src={img2} /></div>
        <div className={"swiper-slide"}><img src={img3} /></div>
      </Swiper>
      {/* <Swiper {...thumbnailSwiperParams}>
        <div className={"swiper-slide thumb"}><img src={img0} /></div>
        <div className={"swiper-slide thumb"}><img src={img1} /></div>
        <div className={"swiper-slide thumb"}><img src={img2} /></div>
        <div className={"swiper-slide thumb"}><img src={img3} /></div>
      </Swiper> */}
    </div>
  );
};
export default Gallery;