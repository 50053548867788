import React, { Fragment, useState } from "react";
import Page from "../components/Page/Page";
import "./Contact.scss";
import GoogleMap from "../components/Map/Map";
import { CompanyData, Hours } from "../AppData";
import SVGPhoneIcon from "../SVGPhoneIcon";
import SVGEmailIcon from "../SVGEmailIcon";
import { HoursInfo } from "../components/HoursInfo/HoursInfo";

function Contact() {
	const mapHeight = "350px";
	const mapWidth = "100%";

	return (
		<Page id={"contact"} customClass={"pad-top-for-menu"}>
			<div className="contact">
				<div className="contact-container">
					<div className="contact-info">
						<div className="transparent-container">
							<div className="header">
								<h2>Let's Talk</h2>
								<span className="contact-message">
									Contact Us.
								</span>
							</div>

							<div className="phone-email-info">
								<div className="phone-email-info-container">
									<div className="phone-info">
										<SVGPhoneIcon />
										<a
											className="phone-value"
											href={"tel:" + CompanyData.phone}
										>
											{CompanyData.phone}
										</a>
									</div>

									{/* <div className="contact-message-2">No Dine-in, Take-out Only</div> */}

									<div className="email-info">
										<SVGEmailIcon />
										<a
											className="email-value"
											href={"email:" + CompanyData.email }
										>
											{CompanyData.email}
										</a>
									</div>
								</div>
							</div>

							<div className="center-container">
								<div className="infos-container">
									<HoursInfo />
								</div>
							</div>

							<div className="gmaps-container">
								<div className="header">
									<h2>We're here</h2>
									<span className="address-message">
										You're welcome to feel the warm, homely
										atmosphere.
									</span>
								</div>
								<GoogleMap
									height={mapHeight}
									width={mapWidth}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Page>
	);
}

const getTime = (a: Hours) => {
	if (a.isClosed) {
		return <span className="time">Closed</span>;
	} else {
		return (
			<span className="time">
				{a.openTime}-{a.closeTime}
			</span>
		);
	}
};
export default Contact;
